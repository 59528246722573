import { createTheme } from '@mui/material/styles'
import { green, red, blue, grey, orange, deepPurple, yellow } from '@mui/material/colors'

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    error: {
      main: red[500]
    },
    alert: {
      main: orange[800]
    },
    communicationColors: {
      sms: green[500],
      email: deepPurple[500],
      voiceMail: blue[400],
      phoneCall: grey[400]
    },
    production: {
      light: '#50717b',
      main: '#50717b',
      dark: '#747474',
      contrastText: '#fff'
    },
    green,
    red,
    blue,
    primary: {
      main: '#3D585B',
      contrastText: '#f5f5f5'
    },
    secondary: {
      main: '#465469',
      contrastText: '#f5f5f5'
    },
    local: {
      light: yellow[400],
      main: grey[400]
    },
    staging: {
      main: red[700]
    },
    darkGrey: {
      main: grey[400]
    },
    warning: {
      main: orange[800]
    },
    background: {
      default: '#f5f5f5',
      paper: '#f5f5f5'
    }
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: 'rgba(0,0,0,0.4)'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: '120px'
        }
      }
    }
  }
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    error: {
      main: red[500]
    },
    alert: {
      main: orange[800]
    },
    communicationColors: {
      sms: green[500],
      email: deepPurple[500],
      voiceMail: blue[400],
      phoneCall: grey[400]
    },
    production: {
      light: '#50717b',
      main: '#50717b',
      dark: '#747474',
      contrastText: '#fff'
    },
    green,
    red,
    blue,
    grey,
    primary: {
      main: '#7CB0B5',
      contrastText: '#101010'
    },
    secondary: {
      main: '#87A0C4',
      contrastText: '#101010'
    },
    local: {
      light: yellow[400],
      main: grey[800]
    },
    staging: {
      main: red[700]
    },
    darkGrey: {
      main: grey[800]
    },
    warning: {
      main: orange[800]
    },
    background: {
      default: grey[900]
    }
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: 'rgba(0,0,0,0.4)'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: '120px'
        }
      }
    }
  }
})

declare module '@mui/material/styles' {
  interface Palette {
    communicationColors: {
      sms: string
      email: string
      voiceMail: string
      phoneCall: string
    }
    production: Palette['primary']
    local: Palette['primary']
    staging: Palette['primary']
    darkGrey: Palette['primary']
    warning: Palette['primary']
    red: typeof red
    green: typeof green
    blue: typeof blue
  }
  interface PaletteOptions {
    communicationColors?: {
      sms: string
      email: string
      voiceMail: string
      phoneCall: string
    }
    production?: PaletteOptions['primary']
    local?: PaletteOptions['primary']
    staging?: PaletteOptions['primary']
    darkGrey?: PaletteOptions['primary']
    warning?: PaletteOptions['primary']
    alert?: PaletteOptions['primary']
    green?: PaletteOptions['primary']
    red?: PaletteOptions['primary']
    blue?: PaletteOptions['primary']
  }
}
