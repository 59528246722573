// eslint-disable-next-line no-unused-vars
import { consoleDev, baseUrl, baseApiUrl, clientWindow, _ } from '../src/helpers/helper'
/*

*****************   SYNTAX FOR THIS FILE PULL DIRECTLY FROM THE EXAMPLE ***********************

https://github.com/vercel/next.js/tree/canary/examples/with-apollo

***********************************************************************************************

*/
import { useMemo } from 'react'
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { concatPagination } from '@apollo/client/utilities'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import fetch from 'node-fetch'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

// Timeout for the API server's status response.
const statusTimeout = 2000

// Just check if we can get a 200 and return true, every other circumstance should return false.
async function apiServerIsUp() {
  try {
    return (await fetch(baseApiUrl + '/status', { timeout: statusTimeout })).ok
  } catch (_) {
    return false
  }
}

const statusFetch = async (_, init) => {
  return (await apiServerIsUp())
    ? await fetch(baseApiUrl + '/graphql', init)
    : await fetch(baseUrl + '/api/graphql', init)
}

let apolloClient

// See Apollo Link overview for info on how the link chain works for apollo client https://www.apollographql.com/docs/react/api/link/introduction/
const linkChain = from([
  new RetryLink({
    delay: { initial: 100, max: Infinity, jitter: true },
    attempts: { max: 1, retryIf: (error, _operation) => !!error }
  }),
  new HttpLink({ credentials: 'include', fetch: statusFetch })
])

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: linkChain,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination()
          }
        }
      }
    })
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))
      ]
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}

export function gqlErrorMessages(error) {
  if (!error) return
  const returnStringArray = [error.message]
  if (error.networkError && error.networkError.result && error.networkError.result) {
    if (error.networkError.result.errors.length) {
      returnStringArray.push(error.networkError.result.errors[0].message)
    }
  }

  return returnStringArray
}

export const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}
